import cx from "classnames";
import { FC } from "react";
import { StructuredText } from "react-datocms";
import {
  RichTextBlockFragment,
  RichTextComponentFragment,
} from "services/datocms/generated";
import st from "./CustomStructuredText.module.scss";

export const CustomStructuredText: FC<{
  content?: RichTextBlockFragment | RichTextComponentFragment | null;
  className?: string;
}> = ({ content, className }) => {
  if (!content) {
    return null;
  }

  const { textColor, textContent, headingTextSize, backgroundColor } = content;

  const wrapperStyle = {
    "--headingTextSize": `${headingTextSize ?? 32}px`,
    "--backgroundColor": backgroundColor?.hex ?? "transparent",
    "--textColor": textColor?.hex ?? "",
  } as React.CSSProperties;

  return (
    <div
      className={cx(st.structuredTextWrapper, className, "flex flex-col gap-2")}
      style={wrapperStyle}
    >
      <StructuredText data={textContent?.value} />
    </div>
  );
};
